import { Injectable } from "@angular/core";
import {CookieService} from 'ngx-cookie-service';


@Injectable({
	providedIn: 'root',
})

export class CookieStorageService {

    constructor(private cookieService: CookieService){

    }

    set(key: string, value: string, expires?: number){
        this.cookieService.set(key, value, expires);
    }


    get(key: string) {
        return this.cookieService.get(key);
    }

    check(key: string) {
        return this.cookieService.check(key);
    }

    remove(key: string){
        this.cookieService.delete(key);
    }

    removeAll(){
        this.cookieService.deleteAll(); 
    }


}