import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/shared/service/admin.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup;
  public registerForm: FormGroup;
  public active = 1;

  constructor(private adminService: AdminService,
    private router: Router,
  ) {
    
  }

  ngOnInit() {  
    this.loginForm = new FormGroup({
      'mobile': new FormControl(null,[Validators.required, Validators.pattern('^[4|5|6|7|8|9][0-9]{9}$')]),
      'password': new FormControl(null, Validators.required),
    })
  }

  owlcarousel = [
    {
      title: "Welcome to Bringjal Admin",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    },
    {
      title: "Welcome to Bringjal Admin",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    },
    {
      title: "Welcome to Bringjal Admin",
      desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
    }
  ]
  owlcarouselOptions = {
    loop: true,
    items: 1,
    dots: true
  };

  // createLoginForm() {
  //   this.loginForm = this.formBuilder.group({
  //     userName: [''],
  //     password: [''],
  //   })
  // }
  
  onSubmit() {
    console.log('onSubmit called', this.loginForm.value);
    this.adminService.adminLogin(this.loginForm.value.mobile, this.loginForm.value.password).subscribe({
      next: async (data:any)=>{
        console.log(data);
        this.router.navigate(['/dashboard/default']);
        this.loginForm.reset();
      },
      error: async (error:any)=>{
        console.log(error);
      }
    })
  }

}
