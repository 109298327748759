<div class="container-fluid" >
    <div class="row">
        <div class="col-12">
            <a [routerLink]="['/customer-mobile']">
              <h5 class="h5-responsive red-text">
                <u>Back to Customer</u>
              </h5>
            </a>
          </div>
          <div class="col-12">
            <div class="card">
                <div class="card-header">
                  <h5>Edit Mobile Number</h5>
                </div>
                <div class="card-body">
                    <form [formGroup]="mobileForm" (ngSubmit)="mobileDevice()">
                        <div class="form-group">
                            <label for="areaInput">Mobile Number</label>
                            <input type="text" formControlName="mobile" class="form-control" id="areaInput" aria-describedby="mobileHelp" placeholder="Enter mobile number">
                            <small *ngIf="!mobileForm.get('mobile').valid && mobileForm.get('mobile').touched" class="form-text text-muted red-text">
                              <div *ngIf="mobileForm.get('mobile').errors['required']">mobile number is Required</div>
                              
                          </small>
                          </div> 
                          <button type="submit" [disabled]="!mobileForm.valid" class="btn btn-primary btn-block">Submit</button>
                    </form>
                </div>
            </div>
          </div>
    </div>
</div>