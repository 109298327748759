<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5>Inventories</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <div class="card px-3">
                <div class="col-12 mt-4">
                  <button type="button" routerLink="/inventories/add-inventory/new" class="btn btn-primary btn-lg mb-3">
                    Add Inventory
                  </button>
                </div>
                <!-- Search Location Section -->
                <div class="col-12">
                  <app-search-bar (searchDataRecieved)="searchDataRecieved($event)"></app-search-bar>
                  </div>
                <!-- Map Section -->
                <div class="col-12 my-3" *ngIf="apiLoaded | async">
                  <google-map height="450px" width="100%" [center]="center" [zoom]="zoom">
                    <ng-container *ngFor="let data of inventories">
                      <ng-container *ngIf="data?.address">
                        <map-marker #marker="mapMarker" [position]="{
                                            lat: data?.address?.latitude,
                                            lng: data?.address?.longitude
                                          }" [options]="markerOptions" (mapDragend)="markerDragEnd($event)"
                          (mapClick)="openInfoWindow(marker, infoWindow)">
                        </map-marker>
                        <!-- Info Window -->
                        <map-info-window #infoWindow="mapInfoWindow">
                          <div class="info-window-small">
                            <div class="info-window-header">
                              Inventory-ID #{{ data._id }}
                            </div>
                            <div class="info-window-body">
                              <p>
                                <strong>Capacity:</strong> {{ data.capacity }}
                              </p>
                              <p>
                                <strong>Address:</strong>
                                {{ data.name }}
                              </p>
                              <!-- Buttons View And Delete -->
                              <div class="d-flex justify-content-between align-content-center gap-2">
                                <button (click)="editInventory(data._id)" class="btn btn-add">
                                  <span>Edit</span>
                                </button>
                                <button (click)="deleteInventoryData(data._id)" class="btn btn-remove">
                                  <span>Delete</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </map-info-window>
                        </ng-container>
                        </ng-container>
                        </google-map>
                        </div>
              </div>
            </div>
            </div>
        </div>
        </div>
    </div>
    </div>
    </div>