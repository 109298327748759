import { HttpClient } from "@angular/common/http";
import { Component, NgZone, OnInit, ViewChild } from "@angular/core";
import { GoogleMap, MapInfoWindow, MapMarker } from "@angular/google-maps";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, map, catchError, of, Observer } from "rxjs";
import { InventoriesService } from "src/app/shared/service/inventories.service";

@Component({
  selector: "app-inventories",
  templateUrl: "./inventories.component.html",
  styleUrls: ["./inventories.component.scss"],
})
export class InventoriesComponent implements OnInit {
  inventories: any;
  primaryAddress: string;
  center: google.maps.LatLngLiteral;
  apiLoaded: Observable<boolean>;
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  latitude: number;
  longitude: number;
  markerPosition: google.maps.LatLngLiteral;
  zoom = 14;

  options: google.maps.MapOptions = {
    zoom: 15,
  };

  markerOptions: google.maps.MarkerOptions = {
    draggable: false,
  };

  constructor(
    private httpClient: HttpClient,
    private ngZone: NgZone,
    private route: ActivatedRoute,
    private router: Router,
    private _InventoriesService: InventoriesService
  ) {
    setTimeout(() => {
      this.apiLoaded = this.httpClient
        .jsonp(
          "https://maps.googleapis.com/maps/api/js?key=AIzaSyC2kDhE4vTqxKvhe4OBOZLpjlptfB5M_zU&libraries=drawing",
          "callback"
        )
        .pipe(
          map(() => true),
          catchError(() => of(false))
        );
    }, 2000);
  }

  ngOnInit(): void {
    this.allInventoriesData();

  }

  // Map marker drag And drop GeoCoordinates
  markerDragEnd(event: google.maps.MapMouseEvent) {
    console.log("Marker drag end", event.latLng.toJSON());

    this.getPosition(event.latLng.toJSON()).subscribe((data: any) => {
      console.log(data);
    });
  }

  // Search GeoCoordinates Along with address
  searchDataRecieved(data) {
    console.log("data received from child component:", data);
    this.primaryAddress = data.searchAddr;
    this.latitude = data.coordinates.lat;
    this.longitude = data.coordinates.lng;
    this.center = {
      lat: data.coordinates.lat,
      lng: data.coordinates.lng,
    };
    this.markerPosition = this.center;
  }

  // Get GeoCoordinates from address
  getPosition(position): Observable<any> {
    console.log("coordinates from getPosition method", position);
    return new Observable((observer: Observer<any>) => {
      const coordinates = {
        lat: position.lat,
        lng: position.lng,
      };
      console.log("coordinated before geocode:", coordinates);
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        {
          location: coordinates,
        },
        (results, status) => {
          this.ngZone.run(() => {
            console.log(
              "address from coordinates:",
              results[0].formatted_address
            );
            const res = {
              coordinates: coordinates,
              searchAddr: results[0].formatted_address,
            };
            this.primaryAddress = res.searchAddr;
            console.log("coordinates name", this.primaryAddress);
            observer.next(res);
            console.log("observer next", res);
            observer.complete();
          });
        }
      );
    });
  }

  // Map View Marker information
  public openInfoWindow(marker: MapMarker, infoWindow: MapInfoWindow) {
    infoWindow.open(marker);
  }

  // Get All Inventories From Server
  allInventoriesData() {
    this._InventoriesService.getAllInventories().subscribe((response: any) => {
      this.inventories = response.result;
      // Center the map on the first inventory
      this.center = {
        lat: this.inventories[0].address.latitude,
        lng: this.inventories[0].address.longitude
      }
      this.markerPosition = this.center;
      console.log("The marker position in GetAllInventoriesData:- ", this.markerPosition);
      console.log("🚀 ~ file: inventories.component.ts:122 ~ InventoriesComponent ~ inventories:-- ", this.inventories);
    });
  }

  //  Delete Inventory
  deleteInventoryData(id: any) {
    if (confirm("Are you sure you want to delete this inventory?")) {
      this._InventoriesService.deleteInventory(id).subscribe((response: any) => {
        this.inventories = response;
        console.log(this.inventories, "======= Delete Inventory ========");
      });
    }
  }

  // View Inventory
  editInventory(id: any) {
    console.log("🚀 ~ file: inventories.component.ts:143 ~ InventoriesComponent ~ router:-- ", id)
    this.router.navigate(["/inventories/add-inventory/", id]);
  }
}
