<div class="container-fluid">
  <div class="row">
    @if(!membershipId){
    <div class="col-12 pt-4">
      <div class="card">
      <div class="card-body">
      <button type="button" (click)="addMembership()" class="btn btn-primary">
        Add Membership
      </button>
      </div>
      </div>
    </div>
    }@else{
    <div class="col-12 pt-4">
      <div class="card">
        <div class="card-header">
          <h1>Membership</h1>
      </div>
      <div class="card-body">
        
      <input
        type="number"
        class="form-control no-left-border"
        aria-describedby="basic-addon2"
        name="month"
        placeholder="please enter month"
        id="month"
        #month
      />
      <button
        type="button"
        (click)="extendMembership(month.value)"
        class="btn btn-primary mt-2"
      >
        Extend Membership
      </button>
      </div>
      </div>
    </div>
    }
  </div>
</div>
