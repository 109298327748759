import { ErrorHandler, Injectable, Inject, Injector } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class globalErrorHandler implements ErrorHandler {
  constructor(@Inject(Injector) private injector: Injector) {}

  //Handle all the errors from the application
  handleError(error: Error | HttpErrorResponse) {
    console.log("I am in error handler");
    if (error instanceof HttpErrorResponse) {
      //server or connection error happened
      console.log("in error instance of http error");
      if (!navigator.onLine) {
        //handler offline error
        //this.toastrService.error('You are not connected to internet');
        console.error("You are not connected to internet");
      } else {
        //handle http error
        if (error.error instanceof Error) {
          console.log("I am an http error");
          //this.toastrService.error('You are not connected to internet');

          console.log("An error occured : ", error.error.message);
        } else {
          if (error.status === 0) {
            //this.toastrService.error("Connection with server is lost");
            console.log("Connection with server is lost");
          }
          if (error.status === 403) {
            //this.toastrService.error(error.error.message);
            console.log("server gives error 403 : ", error.error.message);
          }
          if (error.status === 500) {
            // this.toastrService.error("server gives error 500 : ",error.error.message);
            console.log("server gives error 500 : ", error.error.message);
          }
          if (error.status === 400) {
            //this.toastrService.error("server gives error 400 : ",error.error.message);
            console.log("server gives error 400 : ", error.error.message);
          }
          if (error.status === 404) {
            //this.toastrService.error("server gives error 404 : ",error.error.message);
            console.log("server gives error 404 : ", error.error.message);
          }
          console.log("Backend returned status code : ", error.status);
          console.log("Response Body", error.error);
        }
      }
    } else {
      //this.toastrService.error(error.message);

      //Handle client error
      console.log("client error", error);
    }
  }
}
