import { Injectable } from '@angular/core';
import { globalErrorHandler } from './error-handler';
import { GlobalHttpService } from './globalhttpservice.service';
import { GlobalContextService } from './globalstorage.service';
import { Observable, Observer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InterestedUsersService {

  token: string;
  user: any;
  constructor(
    private _globalHttpService: GlobalHttpService,
    private _globalErrorHandler: globalErrorHandler,
    private _globalStorageService: GlobalContextService
  ) {
    this._globalStorageService.getUser.subscribe((user) => {
      if (user.userToken) {
        this.token = user.userToken;
        console.log(this.token);
        this.user = user.userData;
      }
    });
  }
  getAllInterestedUsers() {
    var headers = new Headers({
      "Content-Type": "application/json",
      token: this.token,
    })
    return Observable.create((observer: Observer<any>) => {
      var url = "https://0zl0c51c8f.execute-api.ap-south-1.amazonaws.com/dev/api/admin/project/fetch/all/interested/Users";
      console.log(url);
      this._globalHttpService
        .getDataFromServiceFixedApi(url)
        .subscribe(
          (data) => {
            observer.next(data);
            console.log(data);
            observer.complete();
          },
          (error) => {
            this._globalErrorHandler.handleError(error);
            observer.error(error);
            console.log(error);
          }
        );
    });
  }
}