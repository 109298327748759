import { Component, inject, Input } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AdminService } from "src/app/shared/service/admin.service";

@Component({
  selector: "app-membership-customer",
  standalone: true,
  imports: [],
  templateUrl: "./membership-customer.component.html",
  styleUrl: "./membership-customer.component.scss",
})
export class MembershipCustomerComponent {
  @Input() membershipId: string;
  @Input({ required: true }) userId: string;
  _AdminService = inject(AdminService);
     toastr=inject(ToastrService)  
  addMembership() {
    this._AdminService.addMembership(this.userId).subscribe({
      next: (data) => {
        console.log(data);
        this.membershipId = data.data.membership;
        this.toastr.success('Hurray', 'Membership added');
      },
      error: (err) => console.log(err),
    });
  }
  extendMembership(month: number) {
    this._AdminService.extendMembership(this.userId, month).subscribe({
      next: (data) => {
        console.log(data);
        this.toastr.success('Hurray', 'Membership extended');
      },
      error: (err) => console.log(err),
    });
  }
}
