import { HttpClient } from "@angular/common/http";
import { Component, ElementRef, ViewChild } from "@angular/core";
import { GoogleMap, MapMarker, MapInfoWindow } from "@angular/google-maps";
import { Observable, map, catchError, of } from "rxjs";
import { InterestedUsersService } from "src/app/shared/service/interested-users.service";

@Component({
  selector: "app-interested-users",
  templateUrl: "./interested-users.component.html",
  styleUrl: "./interested-users.component.scss",
})
export class InterestedUsersComponent {
  // Interested User Get Data
  interestedUsers = [];
  interestedUsersLocation: any;
  searchAddress: string;
  latitude: number;
  longitude: number;
  addr: string;
  private primaryAddress: string;
  place: any;
  apiLoaded: Observable<boolean>;
  @ViewChild(GoogleMap, {
    static: false,
  })
  set map(m: GoogleMap) {
    if (m) {
    }
  }

  options: google.maps.MapOptions = {
    zoom: 15,
    //disableDefaultUI: true
  };
  center: google.maps.LatLngLiteral = {
    lat: 12.977717,
    lng: 77.676492,
  };

  markerOptionsOneCan: google.maps.MarkerOptions = {
    draggable: false,
    label: {
      color: "white",
      text: "1",
    },
  };
  markerOptionsTwoCans: google.maps.MarkerOptions = {
    draggable: false,
    icon: {
      url: "http://maps.google.com/mapfiles/ms/icons/purple.png", // Change color here
    },
    label: {
      color: "white",
      text: "2",
    },
  };
  markerOptionsThreeCans: google.maps.MarkerOptions = {
    draggable: false,
    icon: {
      url: "http://maps.google.com/mapfiles/ms/icons/yellow.png", // Change color here
    },
    label: {
      color: "black",
      text: "3",
    },
  };
  markerOptionsFourCans: google.maps.MarkerOptions = {
    draggable: false,
    icon: {
      url: "http://maps.google.com/mapfiles/ms/icons/orange.png", // Change color here
    },
    label: {
      color: "white",
      text: "4",
    },
  };
  markerPosition: google.maps.LatLngLiteral;
  latLng: google.maps.LatLngLiteral;

  @ViewChild("search")
  public searchElementRef: ElementRef;

  usersWithOneCan = [];
  usersWithTwoCans = [];
  usersWithThreeCans = [];
  usersWithFourCans = [];

  constructor(
    private __interestedUsersService: InterestedUsersService,
    private httpClient: HttpClient
  ) {
    setTimeout(() => {
      this.apiLoaded = httpClient
        .jsonp(
          "https://maps.googleapis.com/maps/api/js?key=AIzaSyC2kDhE4vTqxKvhe4OBOZLpjlptfB5M_zU&libraries=drawing",
          "callback"
        )
        .pipe(
          map(() => true),
          catchError(() => of(false))
        );
    }, 2000);
  }
  ngOnInit(): void {
    this.getAllInterestedUsers();
  }

  searchDataRecieved(data) {
    console.log("data recieved from child component:", data);
    this.searchAddress = data.searchAddress;
    this.latLng = data.coordinates;
    this.latitude = data.coordinates.lat;
    this.longitude = data.coordinates.lng;
    this.center = {
      lat: this.latitude,
      lng: this.longitude,
    };
    this.markerPosition = {
      lat: this.latitude,
      lng: this.longitude,
    };
  }
  // Interested User Get Data
  getAllInterestedUsers() {
    this.__interestedUsersService.getAllInterestedUsers().subscribe((response: any) => {
      let dataFromBackend = response.data;
      dataFromBackend.forEach((el) => {
        var x = {
          coordinates: { lat: el.interesteduserlocation.location.coordinates[1], lng: el.interesteduserlocation.location.coordinates[0] },
          name: el.name,
          mobile: el.mobile,
          email: el.email,
          timestamp: el.Date_Created,
        }
        this.interestedUsers.push(x);

      })
      console.log("🚀 ~ file: interested-users.component.ts:128 ~ InterestedUsersComponent ~ interestedUsers:-- ", this.interestedUsers);
    })
  }


  public openInfoWindow(marker: MapMarker, infoWindow: MapInfoWindow) {
    infoWindow.open(marker);
  }
}
