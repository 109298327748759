import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AdminService } from 'src/app/shared/service/admin.service';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-wallet-refund-summary',
  standalone: true,
  imports: [NgIf,NgFor,NgClass,SharedModule,ReactiveFormsModule],
  templateUrl: './wallet-refund-summary.component.html',
  styleUrl: './wallet-refund-summary.component.scss'
})
export class WalletRefundSummaryComponent {
  issueRefundToWalletForm: FormGroup;
  previousTransactions = [];
  prevTransactionLength: number;
  walletSummary: boolean;
  showmoreButton:boolean=true
  @Input({required:true}) userId:string

  constructor(private _AdminService : AdminService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    
     this.prevTransactionLength = 0;
    this.issueRefundToWalletForm = new FormGroup ({
      amount: new FormControl(null , Validators.required),
      message: new FormControl(null , Validators.required)
    });

    this._AdminService.getWalletSummaryOfUser(0, this.userId).subscribe({
      next:(data)=>{
            console.log("get wallet summary",data);
      this.walletSummary = true;
      var transactions = data.result;
      if(transactions.length<2){
        this.showmoreButton=false
      }
      this.prevTransactionLength+=transactions.length;
      transactions.forEach(element => {
      this.previousTransactions.push(element) ;
    })
      },
      error:(err) =>console.log(err)
     })
  }

  issueRefund(){

 
     this._AdminService.refunMoneyTowallet(this.userId, this.issueRefundToWalletForm.value.amount, this.issueRefundToWalletForm.value.message).subscribe({
      next:(resData)=>{
        console.log(resData),
             this.toastr.success('Hurray', 'Money transaction completed in your wallet');
      },
      error:(err) =>console.log(err)

  });
}

  showmore(){
     this._AdminService.getWalletSummaryOfUser(this.prevTransactionLength+2, this.userId).subscribe({
      next:(data)=>{
            console.log("get wallet summary",data);
      this.walletSummary = true;
      var transactions = data.result;
      if(transactions.length<2){
        this.showmoreButton=false
      }
      this.prevTransactionLength+=transactions.length;
      transactions.forEach(element => {
      this.previousTransactions.push(element) ;
    });
    
      },
      error:(err) =>console.log(err)
     });
}
}
