import { Injectable } from "@angular/core";
import { GlobalHttpService } from "./globalhttpservice.service";
import { globalErrorHandler } from "./error-handler";
import { GlobalContextService } from "./globalstorage.service";
import { Observable, Observer } from "rxjs";
import { AppConstants } from "../entities/appconstants";

@Injectable({
  providedIn: "root",
})
export class TrackingCodeService {
  token: string;
  user: any;
  constructor(
    private _globalHttpService: GlobalHttpService,
    private _globalErrorHandler: globalErrorHandler,
    private _globalStorageService: GlobalContextService
  ) {
    this._globalStorageService.getUser.subscribe((user) => {
      if (user.userToken) {
        this.token = user.userToken;
        console.log(
          "🚀 ~ file: tracking-code.service.ts:20 ~ TrackingCodeService ~ token:-- ",
          this.token
        );
        this.user = user.userData;
      }
    });
  }

  // getAllTrackingCodes
  getAllTrackingCodes(): Observable<any> {
    var headers = new Headers({
      "Content-Type": "application/json",
      token: this.token,
    });
    console.log("User Token", this.token);
    return Observable.create((observer: Observer<any>) => {
      var url = AppConstants.baseUrl3 + AppConstants.getTrackingInfo;
      console.log(url);
      this._globalHttpService
        .getDataFromServiceFixedApi(url, headers)
        .subscribe(
          (data) => {
            observer.next(data);
            console.log(data);
            observer.complete();
          },
          (error) => {
            this._globalErrorHandler.handleError(error);
            observer.error(error);
            console.log(error);
          }
        );
    });
  }
  // getTrackingCodesById
  getTrackingCodesById(Id: any): Observable<any> {
    var headers = new Headers({
      "Content-Type": "application/json",
      token: this.token,
    });
    console.log("User Token", this.token);
    return Observable.create((observer: Observer<any>) => {
      var url = AppConstants.baseUrl3 + AppConstants.getTrackingInfoById + Id;
      console.log(url);
      this._globalHttpService
        .postDataToServiceFixedApi(url, null, headers)
        .subscribe(
          (data) => {
            observer.next(data);
            console.log(data);
            observer.complete();
          },
          (error) => {
            this._globalErrorHandler.handleError(error);
            observer.error(error);
            console.log(error);
          }
        );
    });
  }
  // addTrackingCode
  addTrackingCode(
    categoryCode: any, categoryDescription: any, identifierCode: any, identifierDescription: any, campaign_type: any, areaRadius: any, finalCode: any, finalDescription: any, primaryAddress: any, coordinates: any): Observable<any> {
    var headers = new Headers({
      "Content-Type": "application/json",
      token: this.token,
    });
    console.log(this.token);
    var body = {
      category_code: categoryCode,
      category_description: categoryDescription,
      identifier_code: identifierCode,
      identifier_description: identifierDescription,
      campaign_type: campaign_type,
      area_radius_of_campaign: areaRadius,
      code: finalCode,
      description: finalDescription,
      primary_address: primaryAddress,
      coordinates: coordinates
    };
    console.log(primaryAddress, campaign_type);
    console.log(body.coordinates);
    console.log(body);
    return Observable.create((observer: Observer<any>) => {
      var url = AppConstants.baseUrl3 + AppConstants.addTracking;
      console.log(
        "🚀 ~ file: tracking-code.service.ts:103 ~ TrackingCodeService ~ url:-- ",
        url
      );
      this._globalHttpService
        .postDataToServiceFixedApi(url, JSON.stringify(body), headers)
        .subscribe(
          (data) => {
            observer.next(data);
            console.log(" data tracking-code service: ", data);
            observer.complete();
          },
          (error) => {
            this._globalErrorHandler.handleError(error);
            observer.error(error);
            console.log(" error tracking-code service: ", error);
          }
        );
    });
  }
  // editTrackingCode
  editTrackingCode(
    id: any,
    categoryCode: any,
    categoryDescription: any,
    identifierCode: any,
    identifierDescription: any,
    campaign_type: any,
    areaRadius: any,
    finalCode: any,
    finalDescription: any,
    primaryAddress: any, coordinates: any
  ): Observable<any> {
    var headers = new Headers({
      "Content-Type": "application/json",
      token: this.token,
    });
    var body = {
      category_code: categoryCode,
      category_description: categoryDescription,
      identifier_code: identifierCode,
      identifier_description: identifierDescription,
      campaign_type: campaign_type,
      area_radius_of_campaign: areaRadius,
      code: finalCode,
      description: finalDescription,
      primary_address: primaryAddress,
      coordinates: coordinates
    };
    console.log(JSON.stringify(body));
    return Observable.create((observer: Observer<any>) => {
      var url = AppConstants.baseUrl3 + AppConstants.editTracking + id;
      console.log(
        "🚀 ~ file: tracking-code.service.ts:157 ~ TrackingCodeService ~ url:-- ",
        url
      );
      this._globalHttpService
        .postDataToServiceFixedApi(url, JSON.stringify(body), headers)
        .subscribe((data) => {
          observer.next(data);
          console.log(" data tracking-code service: ", data);
          observer.complete();
        })
    });
  }
  // deleteTrackingCode
  deleteTrackingCode(id: any): Observable<any> {
    var headers = new Headers({
      "Content-Type": "application/json",
      token: this.token,
    });
    var body = {
      id: id,
    };
    return Observable.create((observer: Observer<any>) => {
      var url = AppConstants.baseUrl3 + AppConstants.deleteTracking;
      console.log(
        "🚀 ~ file: tracking-code.service.ts:183 ~ TrackingCodeService ~ url:-- ",
        url
      );
      this._globalHttpService
        .postDataToServiceFixedApi(url, JSON.stringify(body), headers)
        .subscribe((data) => {
          observer.next(data);
          console.log(
            "🚀 ~ file: tracking-code.service.ts:189 ~ TrackingCodeService ~ data:-- ",
            data
          );
          observer.complete();
        })
    });
  }
  // activateTrackingCode
  activateTrackingCode(id): Observable<any> {
    var headers = new Headers({
      "Content-Type": "application/json",
      token: this.token,
    });
    return Observable.create((observer: Observer<any>) => {
      var url = AppConstants.baseUrl3 + AppConstants.activateTracking + id;
      console.log(
        "🚀 ~ file: tracking-code.service.ts:215 ~ TrackingCodeService ~ url:-- ",
        url
      );
      this._globalHttpService
        .postDataToServiceFixedApi(url, null, headers)
        .subscribe(
          (data) => {
            observer.next(data);
            console.log(
              "🚀 ~ file: tracking-code.service.ts:222 ~ TrackingCodeService ~ data:-- ",
              data
            );
            observer.complete();
          },
          (error) => {
            this._globalErrorHandler.handleError(error);
            observer.error(error);
            console.log(
              "🚀 ~ file: tracking-code.service.ts:227 ~ TrackingCodeService ~ error:-- ",
              error
            );
          }
        );
    });
  }
  // deactivateTrackingCode
  deactivateTrackingCode(id): Observable<any> {
    var headers = new Headers({
      "Content-Type": "application/json",
      token: this.token,
    });
    return Observable.create((observer: Observer<any>) => {
      var url = AppConstants.baseUrl3 + AppConstants.deactivateTracking + id;
      console.log(
        "🚀 ~ file: tracking-code.service.ts:245 ~ TrackingCodeService ~ url:-- ",
        url
      );
      this._globalHttpService
        .postDataToServiceFixedApi(url, null, headers)
        .subscribe(
          (data) => {
            observer.next(data);
            console.log(
              "🚀 ~ file: tracking-code.service.ts:253 ~ TrackingCodeService ~ data:-- ",
              data
            );
            observer.complete();
          },
          (error) => {
            this._globalErrorHandler.handleError(error);
            observer.error(error);
            console.log(
              "🚀 ~ file: tracking-code.service.ts:257 ~ TrackingCodeService ~ error:-- ",
              error
            );
          }
        );
    });
  }
}
