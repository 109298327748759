<div class="container-fluid">
    <div class="row">
        <div class="col-12">
        <div class="card">
            <div class="card-header">
               <div class="">
                <h5 class="float-start">Search User by mobile number</h5>
                <div class="float-end">
                    @if(user._id &&  user.mobile){
                    <button type="button" class="btn btn-outline-secondary btn-sm me-2" (click)="navigateEditMobile()">
                        <i class="bi bi-person-plus-fill"></i> Edit Mobile Number</button>
                        <button type="button" class="btn btn-outline-secondary btn-sm" (click)="navigateEditAddress()">
                            <i class="bi bi-person-plus-fill"></i> Edit Address</button>
                    }
                </div>
               </div>
            </div>
            <div class="card-body">
                
                <div class="input-group mb-3">
                    <span class="input-group-text no-right-border" id="basic-addon1">
                        <i class="bi bi-search"></i>
                      </span>
                    <input type="number" class="form-control no-left-border" #searchmobile placeholder=" Search User  by mobile number" aria-label="Search User  by mobile number" aria-describedby="basic-addon2">
                    <span class=""><button type="button" class="btn btn-primary" (click)="searchCustomer(searchmobile)">
                        Search </button></span>
                </div>
            </div>
        </div>
    </div>
    </div>
</div>
<app-customer-order [user]="user"/>
<app-membership-customer [membershipId]="user.membership" [userId]="user._id"/>
<app-wallet-refund-summary [userId]="user._id"/>