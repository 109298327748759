<div class="container-fluid" *ngIf="user.status.subscribed">
  <div class="row">
    @if(!user.order_placed){
    <div class="col-12">
      <div class="card">
      <div class="card-body">
      <button
        type="button"
        (click)="placeOrder()"
        class="btn btn-primary btn-lg btn-block"
      >
        Place Order For The Customer
      </button>
      </div>
      </div>
    </div>
    }@else{
      @if(!order){
        <div class="col-12">
          <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-6 mt-3">
                <button
                  type="button"
                  (click)="orderCancel()"
                  class="btn btn-danger btn-md btn-block"
                >
                  Cancel Order
                </button>
              </div>
              <div class="col-6 mt-3">
                <button
                  type="button"
                  (click)="markCompleteOrder()"
                  class="btn btn-danger btn-md btn-block"
                >
                  Mark Complete Order
                </button>
              </div>
            </div>
          </div>
          </div>
        </div>
      }@else{
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h1>Order Details</h1>
            </div>
            <div class="card-body">
              <div class="row">
    
                <div class="col-md-6 col-12">
                  <b>Order Id:</b> {{ order.orderid }}
                </div>
                <div
                  class="col-md-6 col-12"
                >
                  <b>Total quantity:</b> {{ order.order_items.cart_quantity }}
                </div>
                <div class="col-md-6 col-12">
                  <b>Order placed</b>
                  {{ order.order_status.order_placed.placed_at | relativedate }}
                </div>
                <div class="col-md-6 col-12">
                  <b>Order Total:</b> &#8377;
                  {{ order.order_items.final_Payable_amount }}
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-md-6 col-12">
                      <b>Total Amount Cleared:</b> &#8377;
                      {{ order.payment_status.paid }}
                    </div>
                    <div class="col-md-6 col-12">
                      <b>Amount to be Paid:</b> &#8377;
                      {{ order.payment_status.balance }}
                    </div>
                  </div>
                </div>
                <div
                  class="col-12 col-md-6"
                  *ngFor="let item of order.payment_details"
                >
                  <small class="grey-text"
                    >&#8377; {{ item.amount }} paid through {{ item.type }}</small
                  >
                </div>
                <div class="col-12">
                  <hr />
                </div>
                @if(order?.order_items?.items?.length > 0) {
                  <div class="col-12" >
                    <dl class="row" *ngFor="let item of order.order_items.items">
                      <dd class="col-lg-9 col-8">
                        <b>{{item.item_quantity}} X {{item.item_name}}</b>
                        <!-- In place of per_item_deposit there was item_total_deposit -->
                        <small class="grey-text">&nbsp;(item Deposit: &#8377;{{item.per_item_deposit}})</small>
                      </dd>
                      <!-- In place of item_final_price there was item_subtotal -->
                      <dd class="col-lg-3 col-4">
                        &#8377; {{item.item_final_price }}
                      </dd>
                    </dl>
                    
                    <!-- <hr> -->
                  </div>
                }
                
                <div class="col-12">
                  <hr />
                </div>
                <div
                  class="col-12"
                  *ngIf="
                    (order.order_type.installation &&
                      order.order_items.membership_applied) ||
                    order.order_type.membership_plan_subscription
                  "
                >
                  <b>{{ order.order_items.cart_membership_name }}</b> at &#8377;
                  {{ order.order_items.cart_membership_cost_display }}
                </div>
                <div
                  class="col-12"
                  *ngIf="
                    order.order_items.cart_items_deposit ||
                    order.order_items.device_deposit
                  "
                >
                  <dl class="row">
                    <dt class="col-12">
                      <small
                        ><b
                          class="grey-text"
                          *ngIf="order.order_items.cart_items_deposit"
                          >Water can Deposit: &#8377;
                          {{ order.order_items.cart_items_deposit }}</b
                        ></small
                      >
                    </dt>
                    <dt class="col-12">
                      <small
                        ><b
                          class="grey-text"
                          *ngIf="order.order_items.device_deposit"
                          >Device Deposit: &#8377;
                          {{ order.order_items.device_deposit }}</b
                        ></small
                      >
                    </dt>
                    <!-- <dt class="col-12">
                                          <small><b class="grey-text" *ngIf="order.order_items.cart_mem_membership_deposit">Deposit for 2 water can containers and DropPy device: &#8377; {{order.order_items.cart_mem_membership_deposit}}</b></small>
                                        </dt> -->
                  </dl>
                </div>
                <div class="col-12">
                  <hr />
                </div>
                <div class="col-12">
                  <dl class="row">
                    <dt class="col-sm-12">
                      <b>Total</b>&nbsp;<b
                        >&#8377; {{ order.order_items.final_Payable_amount }}</b
                      ><span
                        *ngIf="
                          order.order_items.cart_items_deposit ||
                          order.order_items.device_deposit
                        "
                        >(&#8377; {{ order.order_items.total_deposit }} Fully
                        refundable deposit)</span
                      >
                    </dt>
                  </dl>
                </div>
                <div class="col-12">
                  <hr />
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-md-6 col-12 d-flex align-items-center">
                      <div class="row">
                        <div class="col-12 mt-3">
                          <button
                            type="button"
                            (click)="orderCancel()"
                            class="btn btn-danger btn-md btn-block"
                          >
                            Cancel Order
                          </button>
                        </div>
                        <div class="col-12 mt-3">
                          <button
                            type="button"
                            (click)="markCompleteOrder()"
                            class="btn btn-danger btn-md btn-block"
                          >
                            Mark Complete Order
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="stepper d-flex flex-column mt-5 ml-2">
                        <!-- Order Placed -->
                        <div class="d-flex mb-1">
                          <div class="d-flex flex-column pr-4 align-items-center">
                            <div
                              class="rounded-circle py-2 px-2"
                              [ngClass]="{
                                'bg-success text-dark':
                                  order.order_status.order_placed.status,
                                'btn-primary text-light':
                                  !order.order_status.order_placed.status
                              }"
                            >
                              <app-feather-icons icon="box"></app-feather-icons>
                            </div>
                            <div class="line h-100"></div>
                          </div>
                          <div>
                            <h5 class="text-dark"><b>Order Placed</b></h5>
                            <p class="lead text-warning pb-3">
                              Order Placed
                              <span class="text-success">{{
                                order.order_status.order_placed.placed_at
                                  | relativedate
                              }}</span>
                              through
                              <span
                                class="green-text"
                                *ngIf="
                                  order.order_status.order_placed.placed_by.device
                                    .placed_by_device
                                "
                                >DropPy device</span
                              ><span
                                class="green-text"
                                *ngIf="
                                  order.order_status.order_placed.placed_by.webapp
                                "
                                >Web application</span
                              ><span
                                class="green-text"
                                *ngIf="
                                  order.order_status.order_placed.placed_by
                                    .mobileapp
                                "
                                >Mobile application</span
                              ><span
                                class="green-text"
                                *ngIf="
                                  order.order_status.order_placed.placed_by
                                    .deliveryboy.status
                                "
                                >Delivery application</span
                              ><span
                                class="green-text"
                                *ngIf="
                                  order.order_status.order_placed.placed_by.admin
                                    .status
                                "
                                >Admin application</span
                              >
                            </p>
                          </div>
                        </div>
    
                        <!-- Order Assigned -->
                        <div class="d-flex mb-1">
                          <div class="d-flex flex-column pr-4 align-items-center">
                            <div
                              class="rounded-circle py-2 px-2"
                              [ngClass]="{
                                'bg-success text-dark':
                                  order.order_status.delivery_boy_assigned.status,
                                'btn-primary text-light':
                                  !order.order_status.delivery_boy_assigned.status
                              }"
                            >
                              <app-feather-icons icon="check"></app-feather-icons>
                            </div>
                            <div class="line h-100"></div>
                          </div>
                          <div>
                            <h5 class="text-dark"><b>Order Assigned</b></h5>
                            <p
                              class="lead text-warning pb-3"
                              *ngIf="order.order_status.delivery_boy_assigned.name"
                            >
                              Order assigned to
                              {{ order.order_status.delivery_boy_assigned.name }}
                            </p>
                          </div>
                        </div>
    
                        <!--Pickup Assigned  -->
                        <div class="d-flex mb-1">
                          <div class="d-flex flex-column pr-4 align-items-center">
                            <div
                              class="rounded-circle py-2 px-2"
                              [ngClass]="{
                                'bg-success text-dark':
                                  order.order_status.pickup_assigned.status,
                                'btn-primary text-light':
                                  !order.order_status.pickup_assigned.status
                              }"
                            >
                              <app-feather-icons
                                icon="shopping-bag"
                              ></app-feather-icons>
                            </div>
                            <div class="line h-100"></div>
                          </div>
                          <div>
                            <h5 class="text-dark"><b>Pickup Assigned</b></h5>
                            <!-- <p class="lead text-warning pb-3" *ngIf="order.order_status.pickup_assigned.name"></p> -->
                          </div>
                        </div>
    
                        <!-- On the way to pickup -->
                        <div class="d-flex mb-1">
                                              <div class="d-flex flex-column pr-4 align-items-center">
                                                <div class="rounded-circle  py-2 px-2" [ngClass]="{'bg-success text-dark': (order.order_status.delivery_boy_enroute_pickup.status), 'btn-primary text-light': !(order.order_status.delivery_boy_enroute_pickup.status)}"><app-feather-icons icon="shopping-cart"></app-feather-icons></div>
                                                <div class="line h-100"></div>
                                              </div>
                                              <div>
                                                <h5 class="text-dark"><b>On the way to Pickup</b></h5>
                                              </div>
                                            </div>
    
                        <!-- Reached Pickup -->
                        <div class="d-flex mb-1">
                          <div class="d-flex flex-column pr-4 align-items-center">
                            <div
                              class="rounded-circle py-2 px-2"
                              [ngClass]="{
                                'bg-success text-dark':
                                  order.order_status.delivery_boy_at_pickup.status,
                                'btn-primary text-light':
                                  !order.order_status.delivery_boy_at_pickup.status
                              }"
                            >
                              <app-feather-icons icon="map-pin"></app-feather-icons>
                            </div>
                            <div class="line h-100"></div>
                          </div>
                          <div>
                            <h5 class="text-dark"><b>Reached Pickup</b></h5>
                            <!-- <p class="lead text-warning pb-3" *ngIf="order.order_status.delivery_boy_at_pickup.name"></p> -->
                          </div>
                        </div>
                        <!-- Pickup Completed -->
                        <div class="d-flex mb-1">
                          <div class="d-flex flex-column pr-4 align-items-center">
                            <div
                              class="rounded-circle py-2 px-2"
                              [ngClass]="{
                                'bg-success text-dark':
                                  order.order_status.delivery_boy_completed_pickup
                                    .status,
                                'btn-primary text-light':
                                  !order.order_status.delivery_boy_completed_pickup
                                    .status
                              }"
                            >
                              <app-feather-icons
                                icon="play-circle"
                              ></app-feather-icons>
                            </div>
                            <div class="line h-100"></div>
                          </div>
                          <div>
                            <h5 class="text-dark"><b>Pickup Completed</b></h5>
                            <!-- <p class="lead text-warning pb-3" *ngIf="order.order_status.delivery_boy_completed_pickup.name"></p> -->
                          </div>
                        </div>
                        <!-- on the way to delivery -->
                        <div class="d-flex mb-1">
                          <div class="d-flex flex-column pr-4 align-items-center">
                            <div
                              class="rounded-circle py-2 px-2"
                              [ngClass]="{
                                'bg-success text-dark':
                                  order.order_status.delivery_boy_enroute_delivery
                                    .status,
                                'btn-primary text-light':
                                  !order.order_status.delivery_boy_enroute_delivery
                                    .status
                              }"
                            >
                              <app-feather-icons icon="truck"></app-feather-icons>
                            </div>
                            <div class="line h-100"></div>
                          </div>
                          <div>
                            <h5 class="text-dark"><b>On the way to Delivery</b></h5>
                            <!-- <p class="lead text-warning pb-3" *ngIf="order.order_status.delivery_boy_enroute_delivery.name"></p> -->
                          </div>
                        </div>
                        <!-- At Delivery Location -->
                        <div class="d-flex mb-1">
                          <div class="d-flex flex-column pr-4 align-items-center">
                            <div
                              class="rounded-circle py-2 px-2"
                              [ngClass]="{
                                'bg-success text-dark':
                                  order.order_status.delivery_boy_at_drop_location
                                    .status,
                                'btn-primary text-light':
                                  !order.order_status.delivery_boy_at_drop_location
                                    .status
                              }"
                            >
                              <app-feather-icons icon="home"></app-feather-icons>
                            </div>
                            <div class="line h-100"></div>
                          </div>
                          <div>
                            <h5 class="text-dark"><b>At Drop Location</b></h5>
                            <!-- <p class="lead text-warning pb-3" *ngIf="order.order_status.delivery_boy_at_drop_location.name"></p> -->
                          </div>
                        </div>
                        <!-- Delivery Complete -->
                        <div class="d-flex mb-1">
                          <div class="d-flex flex-column pr-4 align-items-center">
                            <div
                              class="rounded-circle py-2 px-2"
                              [ngClass]="{
                                'bg-success text-dark':
                                  order.order_status.order_delivered.status,
                                'btn-primary text-light':
                                  !order.order_status.order_delivered.status
                              }"
                            >
                              <app-feather-icons
                                icon="user-check"
                              ></app-feather-icons>
                            </div>
                            <div class="line h-100"></div>
                          </div>
                          <div>
                            <h5 class="text-dark">
                              <b>Order Delivery Complete</b>
                            </h5>
                            <!-- <p class="lead text-warning pb-3" *ngIf="order.order_status.order_delivered.name"></p> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    
    }
  </div>
</div>
