import { HttpClient } from "@angular/common/http";
import { Component, NgZone, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { GoogleMap, MapInfoWindow, MapMarker } from "@angular/google-maps";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { Observable, map, catchError, of, Observer } from "rxjs";
import { InventoriesService } from "src/app/shared/service/inventories.service";

@Component({
  selector: "app-inventory-form",
  templateUrl: "./inventory-form.component.html",
  styleUrls: ["./inventory-form.component.scss"],
})
export class InventoryFormComponent implements OnInit {
  // Form
  inventoryForm: FormGroup;
  id: any;
  loader: boolean;
  primaryAddress: string;
  editInventory: any;
  // Google Map
  apiLoaded: Observable<boolean>;
  @ViewChild(GoogleMap, {
    static: false,
  })
  set map(m: GoogleMap) {
    if (m) {
    }
  }
  latitude: number;
  longitude: number;
  center: google.maps.LatLngLiteral;
  markerPosition: google.maps.LatLngLiteral;

  options: google.maps.MapOptions = {
    zoom: 15,
  };

  markerOptions: google.maps.MarkerOptions = {
    draggable: true,
  };

  constructor(
    private httpClient: HttpClient,
    private ngZone: NgZone,
    private route: ActivatedRoute,
    private router: Router,
    private _InventoriesService: InventoriesService
  ) {
    setTimeout(() => {
      this.apiLoaded = httpClient
        .jsonp(
          "https://maps.googleapis.com/maps/api/js?key=AIzaSyC2kDhE4vTqxKvhe4OBOZLpjlptfB5M_zU&libraries=drawing",
          "callback"
        )
        .pipe(
          map(() => true),
          catchError(() => of(false))
        );
    }, 2000);
  }

  ngOnInit(): void {
    this.loader = true;
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id = params.get("id");
      console.log("param id:", this.id);
      if (this.id) {
        console.log("id: ", this.id);
        this.loader = true;
        this._InventoriesService
          .getInventoriesById(this.id)
          .subscribe((data: any) => {
            this.editInventory = data.result;
            this.primaryAddress = data.result.address.place;
            console.log("🚀 ~ file: ~ primaryAddress:-- ", this.primaryAddress)
            console.log("Inventory: ", data.result);
            this.latitude = data.result.address.latitude;
            this.longitude = data.result.address.longitude;
            this.center = {
              lat: this.latitude,
              lng: this.longitude,
            }
            this.markerPosition = this.center;
            console.log("🚀 ~ file: inventory-form.component.ts:79 ~ InventoryFormComponent ~ center:-- ", this.center)
            this.inventoryForm = new FormGroup({
              inventoryAreaName: new FormControl(
                data.result.name,
                Validators.required
              ),
              inventoryCapacity: new FormControl(
                data.result.capacity,
                Validators.required
              ),
              secondaryAddress: new FormControl(
                data.result.address.secondaryAddress,
                Validators.required
              ),
              landMark: new FormControl(
                data.result.address.landmark,
                Validators.required
              ),
            });
            this.loader = false;
          });
      } else {
        this.loader = false;
        this.inventoryForm = new FormGroup({
          inventoryAreaName: new FormControl(null, Validators.required),
          inventoryCapacity: new FormControl(null, Validators.required),
          secondaryAddress: new FormControl(null, Validators.required),
          landMark: new FormControl(null, Validators.required),
        });
      }
    });
  }

  // Map marker drag And drop GeoCoordinates
  zoom = 14;
  markerDragEnd(event: google.maps.MapMouseEvent) {
    console.log("Marker drag end", event.latLng.toJSON());
    this.latitude = event.latLng.lat();
    this.longitude = event.latLng.lng();
    this.getPosition(event.latLng.toJSON()).subscribe((data: any) => {
      console.log(data);
    });
  }

  // Map View Marker information
  public openInfoWindow(marker: MapMarker, infoWindow: MapInfoWindow) {
    infoWindow.open(marker);
  }
  // Search GeoCoordinates Along with address
  searchDataRecieved(data) {
    console.log("data received from child component:", data);
    this.latitude = data.coordinates.lat;
    this.longitude = data.coordinates.lng;
    this.primaryAddress = data.searchAddr;
    this.center = {
      lat: data.coordinates.lat,
      lng: data.coordinates.lng,
    };
    this.markerPosition = this.center;
  }

  // Get GeoCoordinates from address
  getPosition(position): Observable<any> {
    console.log("coordinates from getPosition method", position);
    return new Observable((observer: Observer<any>) => {
      const coordinates = {
        lat: position.lat,
        lng: position.lng,
      };
      console.log("coordinated before geocode:", coordinates);
      let geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        {
          location: coordinates,
        },
        (results, status) => {
          this.ngZone.run(() => {
            console.log(
              "address from coordinates:",
              results[0].formatted_address
            );
            var res = {
              coordinates: coordinates,
              searchAddr: results[0].formatted_address,
            };
            this.primaryAddress = res.searchAddr;
            console.log("coordinates name", this.primaryAddress);
            observer.next(res);
            console.log("observer next", res);
            observer.complete();
          });
        }
      );
    });
  }
  addInventory() {
    if (this.id) {
      this._InventoriesService
        .editInventory(
          this.id,
          this.inventoryForm.value.inventoryAreaName,
          this.inventoryForm.value.inventoryCapacity,
          this.primaryAddress,
          this.inventoryForm.value.secondaryAddress,
          this.inventoryForm.value.landMark,
          this.latitude,
          this.longitude,
        )
        .subscribe((data: any) => {
          console.log("Edit====>", data);
          this.editInventory = data.result;
          console.log("EditInventory ===>", this.editInventory);
        });
      this.router.navigate(["/inventories/default"]);
    } else {
      this._InventoriesService
        .addInventory(
          this.inventoryForm.value.inventoryAreaName,
          this.inventoryForm.value.inventoryCapacity,
          this.primaryAddress,
          this.inventoryForm.value.secondaryAddress,
          this.inventoryForm.value.landMark,
          this.latitude,
          this.longitude
        )
        .subscribe((data: any) => {
          console.log("Add===>", data);
        });
    }
    this.router.navigate(["/inventories/default"]);
  }
}