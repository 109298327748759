<div class="container-fluid" *ngIf="!loader">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5>Inventories</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12 pt-4">
              <!-- Search Location Section -->
              <div class="col-12">
                <app-search-bar
                  (searchDataRecieved)="searchDataRecieved($event)"
                ></app-search-bar>
              </div>
              <!-- Map Section -->
              <div class="col-12 mb-3" *ngIf="apiLoaded | async">
                <google-map
                  height="400px"
                  width="100%"
                  [center]="center"
                  [zoom]="zoom"
                >
                  <map-marker
                    [position]="markerPosition"
                    [options]="markerOptions"
                    (mapDragend)="markerDragEnd($event)"
                  >
                  </map-marker>
                </google-map>
              </div>
              <form [formGroup]="inventoryForm" (ngSubmit)="addInventory()">
                <!-- Form Section -->
                <!-- Inventory Name -->
                <div class="col-md-12 mt-2">
                  <label for="name">Inventory Area Name</label>
                  <input
                    class="form-control"
                    id="name"
                    formControlName="inventoryAreaName"
                    type="text"
                    name="name"
                    placeholder="Enter Inventory Area Name"
                    required
                  />
                  <!-- Required Field -->
                  <div
                    *ngIf="
                      inventoryForm.get('inventoryAreaName').invalid &&
                      inventoryForm.get('inventoryAreaName').touched
                    "
                    class="text-danger"
                  >
                    <small
                      *ngIf="inventoryForm.get('inventoryAreaName').errors.required"
                      >Inventory Name is required.</small
                    >
                  </div>
                </div>
                <!-- Inventory Capacity -->
                <div class="col-md-12 mt-2">
                  <label for="capacity">Capacity</label>
                  <input
                    class="form-control"
                    type="number"
                    formControlName="inventoryCapacity"
                    name="capacity"
                    placeholder="Enter Inventory Capacity"
                    required
                  />
                  <!-- Required Field -->
                  <div
                    class="text-danger"
                    *ngIf="
                      inventoryForm.get('inventoryCapacity').invalid &&
                      inventoryForm.get('inventoryCapacity').touched
                    "
                  >
                    <small
                      *ngIf="
                        inventoryForm.get('inventoryCapacity').errors.required
                      "
                      >Inventory Capacity is required.</small
                    >
                  </div>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <div class="col-md-6 mt-2 me-2">
                    <label for="secondaryAddress">Secondary Address</label>
                    <input
                      class="form-control"
                      type="text"
                      formControlName="secondaryAddress"
                      name="secondaryAddress"
                      placeholder="Enter Secondary address"
                    />
                    <div
                      class="text-danger"
                      *ngIf="
                        inventoryForm.get('secondaryAddress').invalid &&
                        inventoryForm.get('secondaryAddress').touched
                      "
                    >
                      <small
                        *ngIf="
                          inventoryForm.get('secondaryAddress').errors.required
                        "
                        >*Secondary Address is required.</small
                      >
                    </div>
                  </div>
                  <div class="col-md-6 mt-2">
                    <label for="landMark">Land Mark</label>
                    <input
                      class="form-control"
                      type="text"
                      formControlName="landMark"
                      name="landMark"
                      placeholder="Enter Land Mark"
                    />
                    <div
                      class="text-danger"
                      *ngIf="
                        inventoryForm.get('landMark').invalid &&
                        inventoryForm.get('landMark').touched
                      "
                    >
                      <small
                        *ngIf="inventoryForm.get('landMark').errors.required"
                        >*Land Mark is required.</small
                      >
                    </div>
                  </div>
                </div>
                <div class="d-grid col-6 mx-auto mt-4">
                  <button
                    class="btn btn-primary btn-block"
                    [disabled]="!inventoryForm.valid"
                  >
                    <span *ngIf="!id">Add&nbsp;</span
                    ><span *ngIf="id">Edit&nbsp;</span>Inventory
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
