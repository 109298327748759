import { inject, Injectable } from "@angular/core";

import {
  BehaviorSubject,
  catchError,
  Observable,
  Observer,
  throwError,
  timeout,
} from "rxjs";
import { AppConstants } from "../entities/appconstants";
import { globalErrorHandler } from "./error-handler";
import { GlobalHttpService } from "./globalhttpservice.service";
import { id } from "@swimlane/ngx-charts";
import { JwtHelperService } from "@auth0/angular-jwt";
import { GlobalContextService } from "./globalstorage.service";
import { CookieStorageService } from "./cookie-storage.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
const helper = new JwtHelperService();
@Injectable({
  providedIn: "root",
})
export class AdminService {
  userToken =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7InNlYXJjaF9hZGRyZXNzIjp7ImNvb3JkaW5hdGVzIjpbXX0sInN0YXR1cyI6eyJ2ZXJpZmllZCI6dHJ1ZSwic3Vic2NyaWJlZCI6ZmFsc2UsImFjdGl2YXRlZCI6ZmFsc2UsImluc3RhbGxhdGlvbl9jb21wbGV0ZWQiOmZhbHNlLCJhZGRyZXNzX3ZlcmlmaWVkIjpmYWxzZSwiaW5zdGFsbGF0aW9uX3BlbmRpbmciOmZhbHNlfSwicmVmZXJyYWwiOnsicmVmZXJyYWxfY29kZV91c2VkX29uX3NpZ251cCI6ZmFsc2UsImNvZGUiOiJQdW5vcjNiY2MifSwidHJpYWwiOnsiYWN0aXZlIjpmYWxzZX0sIm9yZGVyX3BsYWNlZCI6ZmFsc2UsInNob3BzX25lYXJieSI6W10sInNjYW5uZWRfaXRlbXMiOltdLCJfaWQiOiI2MTQzMDcyMjhjYTM5MjYxMzI3ZmNkMzkiLCJuYW1lIjoiUHVueWFkYSIsIm1vYmlsZSI6Ijk3MzAxNjI0NDEiLCJlbWFpbCI6InB1bnlhZGEuZEBnbWFpbC5jb20iLCJwYXNzd29yZCI6IiQyYSQxMCRpbnVCQXg1blVweUU5a00xdk9wdWJlVXpYcUZPSlpxRGJSWW9CRW4yM2NKdm9XeFFOSzBidSIsIl9fdiI6MCwiYnJpbmdqYWx3YWxsZXQiOiI2MTQzMDczMThjYTM5MjYxMzI3ZmNkM2IiLCJhZGRyZXNzIjoiNjFkNmEwZjAyYWVhMDYwZWI0MzlmZmVlIiwibG9jYXRpb25EYXRhIjoiNjFkNmEwZjAyYWVhMDYwZWI0MzlmZmVmIn0sImlhdCI6MTY0NDY0NTU3NSwiZXhwIjoxNjQ3MjM3NTc1fQ.OJGL3GXKwSNACEExqD_5pfbz4NhU8yoPwXzPiPBoPyM";
  token: string;
  user: any;
  httpClient = inject(HttpClient);
  constructor(
    private _globalHttpService: GlobalHttpService,
    private _globalErrorHandler: globalErrorHandler,
    private _cookieStorageService: CookieStorageService,
    private _globalStorageService: GlobalContextService
  ) {
    this._globalStorageService.getUser.subscribe((user) => {
      if (user.userToken) {
        this.token = user.userToken;
        this.user = user.userData;
      }
    });
  }

  /*
   * Delivery Area
   */

  fetchPastOrdersForMap(numberOfDays, areaArray): Observable<any> {
    var headers = new Headers({
      "Content-Type": "application/json",
    });
    var body = {
      numberOfDays: numberOfDays,
      areaArray: [areaArray],
    };
    return Observable.create((observer: Observer<any>) => {
      var url =
        "https://65xh1slifj.execute-api.ap-south-1.amazonaws.com/dev/api/watercan/project/omnibot/fetch/users/with/required/fields/and/orders";
      this._globalHttpService
        .postDataToServiceFixedApi(url, body, headers)
        .subscribe((data: any) => {
          observer.next(data);
          observer.complete();
        });
    });
  }

  // fetchActiveOrdersForMap(): Observable<any> {
  //   var headers = new Headers({
  //     "Content-Type": "application/json",
  //   });

  //   return Observable.create((observer: Observer<any>) => {
  //     var url =
  //       "https://65xh1slifj.execute-api.ap-south-1.amazonaws.com/dev/api/watercan/project/omnibot/active/users/with/specific/fields";
  //     this._globalHttpService
  //       .postDataToServiceFixedApi(url, null, headers)
  //       .subscribe((data: any) => {
  //         observer.next(data);
  //         observer.complete();
  //       });
  //   });
  // }

  //add delivery area

  //admin login call

  fetchActiveOrdersForMap(){
    return this.httpClient.post<{Total:number,data:any}>("https://rioa2gejze.execute-api.ap-south-1.amazonaws.com/dev/getPendingamountdetails",{})
  }
  adminLogin(mobile, password): Observable<any> {
    var headers = new Headers({
      "Content-Type": "application/json",
    });
    var body = {
      mobile: mobile,
      password: password,
    };
    console.log("data received..", JSON.stringify(body));
    return Observable.create((observer: Observer<any>) => {
      var url = AppConstants.baseUrl + AppConstants.adminLogin;
      this._globalHttpService
        .postDataToServiceFixedApi(url, JSON.stringify(body), headers)
        .subscribe((data) => {
          console.log("data received..", data);
          this._globalStorageService
            .setuserContext(data.token)
            .subscribe((data) => {
              console.log("data saved..", data);
              observer.next(true);
              observer.complete();
            });
        });
    });
  }
  // mobile
  searchUserByMobile(mobile: number) {
    return this.httpClient.post<{
      success: boolean;
      message: string;
      result: any;
    }>(
      "https://rioa2gejze.execute-api.ap-south-1.amazonaws.com/dev/api/admin/search/user/by/mobile/number",
      {
        mobile: mobile,
      }
    );
  }

  //order

  placeOrderThroughApp(userId: string) {
    return this.httpClient
      .post<{message:string,order:any,userdata:any}>(
        "https://rioa2gejze.execute-api.ap-south-1.amazonaws.com/dev/placeOrderApp",
        { requestID: userId }
      )
      .pipe(
        catchError((err) => {
          return throwError(() => err);
        })
      );
  }

  cancelOrder(orderId: string) {
    console.log(orderId);
    return this.httpClient
      .post(
        "https://rioa2gejze.execute-api.ap-south-1.amazonaws.com/dev/cancelOrder",
        {
          orderid: orderId,
        }
      )
      .pipe(
        catchError((err) => {
          return throwError(() => err);
        })
      );
  }

  markOrderComplete(orderId: string) {
    return this.httpClient.post<{ messsage: string; result: any }>(
      `https://rioa2gejze.execute-api.ap-south-1.amazonaws.com/dev/markOrderComplete/${orderId}`,
      {}
    );
  }

  // membership

  addMembership(userId: string) {
    return this.httpClient.post<{message:string,data:any}>(
      "https://rioa2gejze.execute-api.ap-south-1.amazonaws.com/dev/api/admin/add/membership/to/user",
      {
        userid: userId,
      }
    );
  }

  extendMembership(userId: string,month:number) {
    return this.httpClient.post(
      "https://rioa2gejze.execute-api.ap-south-1.amazonaws.com/dev/extendUserMembership",
      {
        userid: userId,
        months:month
      }
    );
  }

  // wallet

  getWalletSummaryOfUser(limitNum: number, userId: string) {
    return this.httpClient.post<{
      message: string;
      result: [
        {
          BringJalWallet: string;
          amount: number;
          description: string;
          transaction_date: Date;
          transaction_type: { credit: boolean; debit: boolean };
          user: string;
          _id: string;
        }
      ];
    }>(
      "https://rioa2gejze.execute-api.ap-south-1.amazonaws.com/dev/api/admin/get/wallet/summary/for/user/admin",
      {
        userid: userId,
        limitNum: limitNum,
      }
    );
  }
  refunMoneyTowallet(userId: string, amount: number, message: string) {
    return this.httpClient.post(
      "https://rioa2gejze.execute-api.ap-south-1.amazonaws.com/dev/api/admin/refund/money/to/user/wallet",
      {
        userid: userId,
        amount: amount,
        message: message,
      }
    );
  }

  // device

  attachDeviceToUser(userId: string, deviceID: string) {
    return this.httpClient.post<{ messsage: string; result: any }>(
      `https://rioa2gejze.execute-api.ap-south-1.amazonaws.com/dev/api/installation/map/device/to/user/${userId}`,
      {
        droppyID: deviceID,
      }
    );
  }

  detachDeviceToUser(userId: string) {
    return this.httpClient.post<{ messsage: string; result: any }>(
      `https://rioa2gejze.execute-api.ap-south-1.amazonaws.com/dev/api/admin/app/detach/device/from/user/${userId}`,
      {}
    );
  }

  // mobile edit
  mobileNumberEdit(mobile: string, userId: string) {
    return this.httpClient
      .post<{ message: string; result: any }>(
        `https://rioa2gejze.execute-api.ap-south-1.amazonaws.com/dev/api/watercan/whatsapp/modify/mobile/num`,
        { mobile: mobile, userid: userId }
      )
      .pipe(
        catchError((err) => {
          return throwError(() => err);
        })
      );
  }
  
  // address edit

  getUserAddress( userId: string) {
    console.log(userId,"heeece")
    return this.httpClient
      .get<{ message: string; result: any }>(
        `https://rioa2gejze.execute-api.ap-south-1.amazonaws.com/dev/user/address/details/${userId}`)
      .pipe(
        catchError((err) => {
          return throwError(() => err);
        })
      );
  }

  editUserAddress(
    userId: string,
    primary_address: string,
    secondary_address: string,
    lift: boolean,
    floor: number,
    longitude: number,
    latitude: number,
    landmark: string,
    delivery_note: string
  ) {
    return this.httpClient
      .post(
        `https://rioa2gejze.execute-api.ap-south-1.amazonaws.com/dev/api/admin/edit/address/of/user/${userId}`,
        {
          primary_address,
          secondary_address,
          lift,
          floor,
          longitude,
          latitude,
          landmark,
          delivery_note,
        }
      )
      .pipe(
        catchError((err) => {
          return throwError(() => err);
        })
      );
  }
  addressOnMarkerChange(userId: string, latitude: number, longitude: number,primary_address:string) {
    console.log(latitude,longitude)
    return this.httpClient
      .post<{result:any}>(
        `https://rioa2gejze.execute-api.ap-south-1.amazonaws.com/dev/api/admin/on/address/marker/change/of/user`,
        {
          coordinates: [longitude, latitude],
          userid: userId,
        }
      )
      .pipe(
        catchError((err) => {
          return throwError(() => err);
        })
      );
  }

  // active order
  sendWhatsAppMessage(
    amount: number,
    order_id: string,
    userid: string,
    username: string,
    notes: string,
    mobile: string
  ) {
    const payload = {
      type:"Admin",
      amount,
      order_id,
      userid,
      username,
      notes,
      mobile,
    }; 
    const headers = new HttpHeaders({
      'Content-Type': 'application/json', // Specify the content type
    });
    return this.httpClient
      .post(
        "https://rioa2gejze.execute-api.ap-south-1.amazonaws.com/dev/createAGeneralPayLink",
        payload,
        {headers}
      )
      .pipe(
        catchError((err) => {
          return throwError(() => err);
        })
      );
  }
}
