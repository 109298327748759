import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError } from 'rxjs/internal/operators/catchError';
import { TrackingCodeService } from 'src/app/shared/service/tracking-code.service';

@Component({
  selector: 'app-tracking-code',
  templateUrl: './tracking-code.component.html',
  styleUrl: './tracking-code.component.scss'
})
export class TrackingCodeComponent {
  trackingCodes: any[] = [];

  constructor(private router: Router, private route: ActivatedRoute, private _TrackingCodeService: TrackingCodeService) { }

  ngOnInit(){
    this.getTrackingCode();
  }
  // get tracking code
  getTrackingCode() {
    this._TrackingCodeService.getAllTrackingCodes().subscribe((responseData) => {
      this.trackingCodes = responseData.data;
      console.log(this.trackingCodes);
    });
  }
  viewTrackingCode(id) {
    this.router.navigate(['/tracking-code/edit-tracking-code/' + id]);
  }

  // delete tracking code
  deleteTrackingCodes(id) {
    if (confirm("are you sure you want to delete this coupon?")) {
      this._TrackingCodeService.deleteTrackingCode(id).subscribe((responseData) => {
        this.trackingCodes = responseData.data;
        console.log('response data', this.trackingCodes);
        this.getTrackingCode();
      })
    }
  }

  // activate and deactivate tracking code
  activateTrackingCode(id) {
    console.log("🚀 ~ file: tracking-code.component.ts:44 ~ TrackingCodeComponent ~ id:-- ", id)
    this._TrackingCodeService.activateTrackingCode(id).subscribe((responseData) => {
      this.trackingCodes = responseData.data;
      console.log("🚀 ~ file: tracking-code.component.ts:47 ~ TrackingCodeComponent ~ trackingCodes:-- ", this.trackingCodes)
      this.getTrackingCode();
    })

  }

  deactivateTrackingCode(id) {
    console.log("🚀 ~ file: tracking-code.component.ts:53 ~ TrackingCodeComponent ~ id:-- ", id)
    this._TrackingCodeService.deactivateTrackingCode(id).subscribe((responseData) => {
      this.trackingCodes = responseData.data;
      console.log("🚀 ~ file: tracking-code.component.ts:56 ~ TrackingCodeComponent ~ trackingCodes:-- ", this.trackingCodes)
      this.getTrackingCode();
    })
  }
}
