import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { AppConstants } from "../entities/appconstants";
import { GlobalContextService } from "./globalstorage.service";

@Injectable({
  providedIn: "root",
})
export class GlobalHttpService {
  get(arg0: string) {
    throw new Error("Method not implemented.");
  }

  headers = new HttpHeaders();
  constructor(
    private http: HttpClient,
    private _globalContextService: GlobalContextService
  ) {}

  setTokenHeaders(customHeaders, isAppJsonHeaderRequired = true) {
    var objHeaders = {};
    if (isAppJsonHeaderRequired) {
      objHeaders["Content-Type"] = "application/json";
    }

    if (customHeaders != undefined && customHeaders != null) {
      for (let i = 0; i < customHeaders.length; i++) {
        var key = Object.keys(customHeaders[i])[0];
        objHeaders[key] = customHeaders[i][key];
      }
    }
    this.headers = new HttpHeaders(objHeaders);
  }

  postDataToServiceFixedApi(
    url,
    requestBody: any = null,
    customHeaders: any = null,
    isAppJsonHeaderRequired: boolean = true
  ) {
    this.setTokenHeaders(customHeaders, isAppJsonHeaderRequired);
    try {
      return this.http.post(url, requestBody, {
        headers: customHeaders,
      });
    } catch (ex) {
      return ex;
    }
  }

  getDataFromServiceFixedApi(
    url,
    customHeaders: any = null,
    isAppJsonHeaderRequired: boolean = true
  ) {
    this.setTokenHeaders(customHeaders, isAppJsonHeaderRequired);
    try {
      return this.http.get(url, {
        headers: customHeaders,
      });
    } catch (ex) {
      return ex;
    }
  }
}
