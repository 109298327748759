<div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <h5>Tracking Code</h5>
          </div>
          <div class="card-body">
              <div class="row">
                  <div class="col-12">
                    <div class="col-12">
                        <button type="button" routerLink="/tracking-code/add-tracking-code/new" class="btn btn-primary btn-lg float-start">Add New Tracking Code</button>
                        <button type="button" routerLink="/tracking-code/top-performance-codes" class="btn btn-success btn-lg float-end">Top performing Codes</button>
                      </div>
                      <div class="col-12 mt-5">
                        <div class="user-status table-responsive products-table">
                            <table class="table table-bordernone mb-0">
                                <thead>
                                    <tr>
                                        <th scope="col">Code</th>
                                        <th scope="col">Description</th>
                                        <th scope="col">Category Description</th>
                                        <th scope="col">Identifier Description</th>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody *ngFor="let data of trackingCodes; let i = index">
                                    <tr>
                                        <td>{{data.code}}</td>
                                        <td class="digits">{{data.description}}</td>
                                        <td>{{data.category_description}}</td>
                                        <!-- <td ><span *ngIf="data.access.superAdmin">Super Admin</span><span *ngIf="data.access.admin">Admin</span><span *ngIf="data.access.customerSupport">Customer Support</span><span *ngIf="data.access.manager">Manager</span></td> -->
                                        <td>
                                            {{data.identifier_description}}
                                        </td>
                                        <td class="pb-2"> 
                                            <button type="button" (click)="viewTrackingCode(data._id)" class="btn btn-outline-warning btn-sm">Edit</button>
                                        </td>
                                        <td class="pb-2"> 
                                            <button *ngIf="!data.active" type="button" (click)="activateTrackingCode(data._id)"
                                                class="btn btn-outline-success btn-sm">Activate</button>
                                            <button *ngIf="data.active" type="button" (click)="deactivateTrackingCode(data._id)"
                                                class="btn btn-outline-danger btn-sm">Deactivate</button>
                                        </td>
                                        <td class="pb-2"> 
                                            <button type="button" (click)="deleteTrackingCodes(data._id)" class="btn btn-outline-primary btn-sm">Delete</button>
                                        </td>
                                    </tr>
                                    
                                </tbody>

                                <!-- <tbody>
                                    <tr>
                                        <td>Sumanth</td>
                                        <td class="digits">9682587412</td>
                                        <td >sumanth&#64;bringjal.com</td>
                                        <td ><span *ngIf="data.access.superAdmin">Super Admin</span><span *ngIf="data.access.admin">Admin</span><span *ngIf="data.access.customerSupport">Customer Support</span><span *ngIf="data.access.manager">Manager</span></td>
                                        <td class="pb-2"> 
                                            <button type="button" [routerLink]="['/admin-staff/add-admin-staff', data._id]" class="btn btn-outline-primary btn-sm">View</button>
                                        </td>
                                        <td class="pb-2"> 
                                            <button *ngIf="!data.status" type="button" (click)="activateAdmin(data._id)" class="btn btn-outline-primary btn-sm">Activate</button>
                                            <button *ngIf="data.status" type="button" (click)="deactivateAdmin(data._id)" class="btn btn-outline-danger btn-sm">Deactivate</button>
                                        </td>
                                        <td class="pb-2"> 
                                            <button type="button" (click)="deleteAdmin(data._id)" class="btn btn-outline-primary btn-sm">Delete</button>
                                        </td>
                                    </tr>
                                    
                                </tbody> -->
                            </table>
                        </div>
                      </div>
                  </div>
             </div>
         </div>
        </div>
     </div>
     </div>
</div>
