export class AppConstants {
    
    static appconfigUrl = './assets/configuration/webconfig.json';
    static deviceDeposit = 500;
    static apiAccessKey ='token';
    static baseUrl = 'https://rpspvcdbob.execute-api.ap-south-1.amazonaws.com/dev/api/';
    static baseUrl2 = 'https://rjzrjrv4vd.execute-api.ap-south-1.amazonaws.com/dev/api/';
    static baseUrl3 = "https://v8r7a7l5g3.execute-api.ap-south-1.amazonaws.com/dev/api/";
    
    // Authorization URL's
    static adminLogin = 'admin/login'; 

    // Admin Staff URL's
    static addAdminStaff = 'admin/signup';
    static editAdminStaff = 'admin/edit/admin/staff/';
    static getAllAdminStaff = 'admin/get/all/adminstaff';
    static getAdminStaffInfo = 'admin/get/adminstaff/info/';
    static activateAdminStaff = 'admin/activate/adminstaff/';
    static deactivateAdminStaff = 'admin/deactivate/adminstaff/';
    static deleteAdminStaff = 'admin/delete/accessory/details/';

    //Tax Rules URL's
    static getTaxRuleInfo = 'admin/getTaxRuleInfo/';
    static addTaxRule = 'admin/addTaxRule';
    static editTaxRule = 'admin/editTaxRule/';
    static deleteTaxRule = 'admin/delete/taxrule/details';
    static getAllTaxRules = 'admin/getAllTaxRules';

    // Delivery Staff URL's
    static addDeliveryStaff = 'admin/add/deliverystaff';
    static getAllDeliveryStaff = 'admin/get/all/delivery/staff';
    static getDeliveryStaffInfo = 'admin/get/delivery/staff/info/';
    static deleteDeliveryStaff = 'admin/delete/delivery/staff/details';
    static editDeliveryStaff = 'admin/edit/delivery/staff/';
    static activateDeliveryStaff = 'admin/activate/delivery/staff/';
    static deactivateDeliveryStaff = 'admin/deactivate/delivery/staff/';

    // Accessories URL's
    static addAccessory = 'admin/addAccessories';
    static editAccessory = 'admin/editAccessory/';
    static getAllAccessoryInfo = 'admin/getAccessories';
    static getAccessoryInfo = 'admin/getAccessory/';
    static deleteAccessory = 'admin/delete/accessory/details/';
    static activateAccessory = 'admin/activate/the/accessory/';
    static deactivateAccessory = 'admin/deactivate/the/accessory/';

    // Products URL's
    static addProduct = 'admin/addProduct';
    static getAllProducts = 'admin/getProducts';
    static getProductById = 'admin/getProduct/';
    static editProduct = 'admin/editProduct/';
    static deleteProduct = 'admin/delete/product/details';
    static activateProduct = 'admin/activate/the/Product/';
    static deactivateProduct = 'admin/deactivate/the/Product/';

    // Delivery Area URL's

    static getAllDeliveryAreas = 'admin/get/all/deliveryareas';
    static addDeliveryArea = 'admin/addDeliveryArea';
    static editDeliveryArea = 'admin/editDeliveryArea/';

    // Tracking URL's
    static getTrackingInfo = 'admin/project/view/all/tracking/codes';
    static getTrackingInfoById = 'admin/project/fetch/tracking/code/details/';
    static addTracking = 'admin/project/add/tracking/code';
    static editTracking = 'admin/project/edit/tracking/code/details/';
    static deleteTracking = 'admin/project/delete/tracking/code/details/';
    static activateTracking = 'admin/project/activate/tracking/code/';
    static deactivateTracking = 'admin/project/deactivate/tracking/code/';

    // Coupons URL's
    static addCoupon = 'admin/project/add/coupon/code';
    static getCoupons = 'admin/project/view/all/coupon/codes';
    static getCouponById = 'admin/project/fetch/coupon/code/details/';
    static editCoupon = 'admin/project/edit/coupon/code';
    static deleteCoupon = 'admin/project/delete/coupon/code/';
    static activateCoupon = 'admin/project/activate/coupon/code/';
    static deactivateCoupon = 'admin/project/de/activate/tracking/code/';

    // Inventories URL's (Base URL)
    static addInventory = 'admin/add/inventory';
    static editInventory = 'admin/edit/inventory/';
    static getInventoryInfo = 'admin/get/inventory/information/frm/id';
    static getAllInventories = 'admin/get/all/inventories';
    static deleteInventory = 'admin/delete/inventory/with/id';
}
