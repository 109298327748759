<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Interested Users</h5>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <app-search-bar (searchDataRecieved)="searchDataRecieved($event)"></app-search-bar>
                        </div>
                        <div class="col-12 mt-0">
                            <div *ngIf="apiLoaded | async">
                                <google-map width="100%" height="650px" [center]="center" [options]="options">
                                    <ng-container *ngFor="let data of interestedUsers">
                                        <map-marker #marker="mapMarker" [position]="data.coordinates"
                                            [options]="markerOptionsOneCan"
                                            (mapClick)="openInfoWindow(marker, infoWindow)"></map-marker>
                                        <map-info-window #infoWindow="mapInfoWindow">
                                            <div>
                                                <b>{{ data.name }}</b>
                                                <p>{{ data.mobile }}</p>
                                                <p>{{ data.email }} can</p>
                                                <p>{{ data.timestamp | relativedate }}</p>

                                            </div>
                                        </map-info-window>
                                    </ng-container>
                                </google-map>
                                <br />
                            </div>
                            <div>
                                <h3>
                                    <b>Total Interested Users:
                                        {{interestedUsers.length}}
                                    </b>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <!-- Table -->
    <div class="card">
        <div class="card-header">
            <h5>Interested Users List</h5>
        </div>
        <div class="card-body">
            <div class="table-responsive">
                <table class="table table-bordernone">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Mobile</th>
                            <th scope="col">Email</th>
                            <th scope="col">Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of interestedUsers; let i = index">
                            <td>{{ data.name }}</td>
                            <td>{{ data.mobile }}</td>
                            <td>{{ data.email }}</td>
                            <td>{{ data.timestamp | relativedate }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>