import { Component, NgModule, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  ReactiveFormsModule,
} from "@angular/forms";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AdminService } from "src/app/shared/service/admin.service";
import { SharedModule } from "src/app/shared/shared.module";
import { CustomerOrderComponent } from "../customer-order-track/customer-order.component";
import { MembershipCustomerComponent } from "../membership-customer/membership-customer.component";
import { WalletRefundSummaryComponent } from "../wallet-and-refund-summary/wallet-refund-summary.component";

@Component({
  selector: "app-search-mobile-customer",
  standalone: true,
  imports: [SharedModule, ReactiveFormsModule,CustomerOrderComponent,RouterLink,MembershipCustomerComponent,WalletRefundSummaryComponent],
  templateUrl: "./search-mobile-customer.component.html",
  styleUrl: "./search-mobile-customer.component.scss",
})
export class SearchMobileCustomerComponent implements OnInit {
  user:any
  constructor(
    private _AdminService: AdminService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router
  ) {} 

  ngOnInit(): void {
    
  }
  navigateEditMobile(){
    this.router.navigate(['/edit-mobile-number', this.user.mobile, this.user._id])
  }
  navigateEditAddress(){
    this.router.navigate(['/edit-customer-address', this.user._id])
  }

  searchCustomer(mobile:any) {
    
    this._AdminService.searchUserByMobile(mobile.value).subscribe({
      next:(data)=>{
        this.user=data.result
        mobile.value='';
        console.log(data)
    },
    error:(err)=>console.log(err)
    })
  }
  
}
