import { Injectable } from "@angular/core";
import { globalErrorHandler } from "./error-handler";
import { GlobalHttpService } from "./globalhttpservice.service";
import { GlobalContextService } from "./globalstorage.service";
import { Observable, Observer } from "rxjs";
import { AppConstants } from "../entities/appconstants";

@Injectable({
  providedIn: "root",
})
export class InventoriesService {
  token: string;
  user: any;
  constructor(
    private _globalHttpService: GlobalHttpService,
    private _globalErrorHandler: globalErrorHandler,
    private _globalStorageService: GlobalContextService
  ) {
    this._globalStorageService.getUser.subscribe((user) => {
      if (user.userToken) {
        this.token = user.userToken;
        console.log(
          "🚀 ~ file: inventories.service.ts:20 ~ InventoriesService ~ token:-- ",
          this.token
        );
        this.user = user.userData;
      }
    });
  }

  // get all inventories
  getAllInventories(): Observable<any> {
    var headers = new Headers({
      "Content-Type": "application/json",
      token: this.token,
    });
    console.log("User Token", this.token);
    return Observable.create((observer: Observer<any>) => {
      var url = AppConstants.baseUrl + AppConstants.getAllInventories;
      console.log(
        "🚀 ~ file: inventories.service.ts:37 ~ InventoriesService ~ url:-- ",
        url
      );
      this._globalHttpService
        .getDataFromServiceFixedApi(url, headers)
        .subscribe(
          (data) => {
            observer.next(data);
            console.log(
              "🚀 ~ file: inventories.service.ts:48 ~ InventoriesService ~ data:-- ",
              data
            );
            observer.complete();
          },
          (error) => {
            this._globalErrorHandler.handleError(error);
            observer.error(error);
            console.log(error);
          }
        );
    });
  }

  // get inventories by id
  getInventoriesById(id: any): Observable<any> {
    var headers = new Headers({
      "Content-Type": "application/json",
      token: this.token,
    });

    var body = {
      id: id,
    };

    console.log("User Token", this.token);
    return Observable.create((observer: Observer<any>) => {
      var url = AppConstants.baseUrl + AppConstants.getInventoryInfo;
      console.log(
        "🚀 ~ file: inventories.service.ts:69 ~ InventoriesService ~ url:-- ",
        url
      );
      this._globalHttpService
        .postDataToServiceFixedApi(url, JSON.stringify(body), headers)
        .subscribe(
          (data) => {
            observer.next(data);
            console.log(
              "🚀 ~ file: inventories.service.ts:74 ~ InventoriesService ~ data:-- ",
              data
            );
            observer.complete();
          },
          (error) => {
            this._globalErrorHandler.handleError(error);
            observer.error(error);
            console.log(error);
          }
        );
    });
  }
  // Delete inventory
  deleteInventory(id): Observable<any> {
    var headers = new Headers({
      "Content-Type": "application/json",
      token: this.token,
    });
    var body = {
      id: id,
    };
    return Observable.create((observer: Observer<any>) => {
      var url = "https://rjzrjrv4vd.execute-api.ap-south-1.amazonaws.com/dev/api/admin/delete/inventory/with/id";
      console.log("🚀 ~ file: inventories.service.ts:107 ~ InventoriesService ~ url:-- ", url)
      this._globalHttpService
        .postDataToServiceFixedApi(url, JSON.stringify(body), headers)
        .subscribe((data) => {
          console.log("data ==>", data)
          observer.next(data.data);
          observer.complete();
        })
    })
  }

  // Add inventory
  addInventory(
    inventoryAreaName: string,
    inventoryCapacity: number,
    primaryAddress: string,
    secondaryAddress: string,
    landMark: string,
    latitude: number,
    longitude: number
  ): Observable<any> {
    var headers = new Headers({
      "Content-Type": "application/json",
      token: this.token,
    });
    console.log(this.token);
    var body = {
      name: inventoryAreaName,
      capacity: inventoryCapacity,
      primaryAddress: primaryAddress,
      secondaryAddress: secondaryAddress,
      landmark: landMark,
      latitude: latitude,
      longitude: longitude,
    };
    return Observable.create((observer: Observer<any>) => {
      var url = AppConstants.baseUrl + AppConstants.addInventory;
      console.log(
        "🚀 ~ file: inventories.service.ts:132 ~ InventoriesService ~ url:-- ",
        url
      );
      this._globalHttpService
        .postDataToServiceFixedApi(url, JSON.stringify(body), headers)
        .subscribe(
          (data) => {
            observer.next(data);
            console.log(
              "🚀 ~ file: inventories.service.ts:158 ~ InventoriesService ~ data:-- ",
              data
            );
            observer.complete();
          },
          (error) => {
            this._globalErrorHandler.handleError(error);
            observer.error(error);
            console.log(
              "🚀 ~ file: inventories.service.ts:163 ~ InventoriesService ~ error:-- ",
              error
            );
          }
        );
    });
  }

  // Edit inventory
  editInventory(
    id: any,
    inventoryAreaName: string,
    inventoryCapacity: number,
    primaryAddress: string,
    secondaryAddress: string,
    landMark: string,
    latitude: number,
    longitude: number
  ): Observable<any> {
    var headers = new Headers({
      "Content-Type": "application/json",
      token: this.token,
    });
    console.log(this.token);
    var body = {
      name: inventoryAreaName,
      capacity: inventoryCapacity,
      primaryAddress: primaryAddress,
      secondaryAddress: secondaryAddress,
      landmark: landMark,
      latitude: latitude,
      longitude: longitude,
    };
    console.log(JSON.stringify(body));
    return Observable.create((observer: Observer<any>) => {
      var url = AppConstants.baseUrl + AppConstants.editInventory + id;
      console.log(
        "🚀 ~ file: inventories.service.ts:192 ~ InventoriesService ~ url:-- ",
        url
      );
      this._globalHttpService
        .postDataToServiceFixedApi(url, JSON.stringify(body), headers)
        .subscribe((data) => {
          observer.next(data);
          console.log(
            "🚀 ~ file: inventories.service.ts:199 ~ InventoriesService ~ data:-- ",
            data
          );
          observer.complete();
        });
    });
  }
}
