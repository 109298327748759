import { Injectable, HostListener, Inject } from "@angular/core";
import { BehaviorSubject, Observable, Subscriber } from "rxjs";
import { WINDOW } from "./windows.service";
// Menu
export interface Menu {
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: "root",
})
export class NavService {
	public screenWidth: any;
	public collapseSidebar: boolean = false;

	constructor(@Inject(WINDOW) private window) {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true;
		}
  }

	// Windows width
	@HostListener("window:resize", ["$event"])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [
		{
			path: "/dashboard/default",
			title: "Dashboard",
			icon: "home",
			type: "link",
			badgeType: "primary",
			active: false,
		},
		{
			path: "/customer-mobile",
			title: "User-Backup",
			icon: "user",
			type: "link",
			active: false,
		},
		{
			title: "Order-Assignments",
			path: "/order-assignments/default",
			icon: "box",
			type: "link",
			active: false,
		},
		{
			title: "Admin Staff",
			path: "/admin-staff/default",
			icon: "key",
			type: "link",
			active: false,
		},
		{
			title: "Accessory",
			path: "/accessory/default",
			icon: "box",
			type: "link",
			active: false,
		},
		// {
		// 	title: 'Products', icon: 'box', type: 'sub', active: false, children: [
		// 		{
		// 			title: 'Physical', type: 'sub', children: [
		// 				{ path: '/products/physical/category', title: 'Category', type: 'link' },
		// 				{ path: '/products/physical/sub-category', title: 'Sub Category', type: 'link' },
		// 				{ path: '/products/physical/product-list', title: 'Product List', type: 'link' },
		// 				{ path: '/products/physical/product-detail', title: 'Product Detail', type: 'link' },
		// 				{ path: '/products/physical/add-product', title: 'Add Product', type: 'link' },
		// 			]
		// 		},
		// 		{
		// 			title: 'digital', type: 'sub', children: [
		// 				{ path: '/products/digital/digital-category', title: 'Category', type: 'link' },
		// 				{ path: '/products/digital/digital-sub-category', title: 'Sub Category', type: 'link' },
		// 				{ path: '/products/digital/digital-product-list', title: 'Product List', type: 'link' },
		// 				{ path: '/products/digital/digital-add-product', title: 'Add Product', type: 'link' },
		// 			]
		// 		},
		// 	]
		// },
		// {
		// 	title: 'Sales', icon: 'dollar-sign', type: 'sub', active: false, children: [
		// 		{ path: '/sales/orders', title: 'Orders', type: 'link' },
		// 		{ path: '/sales/transactions', title: 'Transactions', type: 'link' },
		// 	]
		// },
		// {
		// 	title: 'Coupons', icon: 'tag', type: 'sub', active: false, children: [
		// 		{ path: '/coupons/list-coupons', title: 'List Coupons', type: 'link' },
		// 		{ path: '/coupons/create-coupons', title: 'Create Coupons', type: 'link' },
		// 	]
		// },
		// {
		// 	title: 'Pages', icon: 'clipboard', type: 'sub', active: false, children: [
		// 		{ path: '/pages/list-page', title: 'List Page', type: 'link' },
		// 		{ path: '/pages/create-page', title: 'Create Page', type: 'link' },
		// 	]
		// },
		// {
		// 	title: 'Media', path: '/media', icon: 'camera', type: 'link', active: false
		// },
		// {
		// 	title: 'Menus', icon: 'align-left', type: 'sub', active: false, children: [
		// 		{ path: '/menus/list-menu', title: 'Menu Lists', type: 'link' },
		// 		{ path: '/menus/create-menu', title: 'Create Menu', type: 'link' },
		// 	]
		// },
		{
			title: "Users",
			icon: "user-plus",
			type: "sub",
			active: false,
			children: [
				{ path: "/users/search-user", title: "Search User", type: "link" },
				{ path: "/users/create-user", title: "Create User", type: "link" },
			],
		},
		// {
		// 	title: 'Vendors', icon: 'users', type: 'sub', active: false, children: [
		// 		{ path: '/vendors/list-vendors', title: 'Vendor List', type: 'link' },
		// 		{ path: '/vendors/create-vendors', title: 'Create Vendor', type: 'link' },
		// 	]
		// },
		// {
		// 	title: 'Localization', icon: 'chrome', type: 'sub', children: [
		// 		{ path: '/localization/translations', title: 'Translations', type: 'link' },
		// 		{ path: '/localization/currency-rates', title: 'Currency Rates', type: 'link' },
		// 		{ path: '/localization/taxes', title: 'Taxes', type: 'link' },
		// 	]
		// },
		// {
		// 	title: 'Reports', path: '/reports', icon: 'bar-chart', type: 'link', active: false
		// },
		// {
		// 	title: 'Settings', icon: 'settings', type: 'sub', children: [
		// 		{ path: '/settings/profile', title: 'Profile', type: 'link' },
		// 	]
		// },
		// {
		// 	title: 'Invoice', path: '/invoice', icon: 'archive', type: 'link', active: false
		// },
		{
			title: "Customers",
			path: "/customers/default/new",
			icon: "user",
			type: "link",
			active: false,
		},
		{
			title: "Products",
			path: "/products/default",
			icon: "droplet",
			type: "link",
			active: false,
		},
		{
			title: "Delivery Areas",
			path: "/delivery-area/default",
			icon: "map",
			type: "link",
			active: false,
		},
		{
			title: "Coupons",
			path: "/coupons/default",
			icon: "gift",
			type: "link",
			active: false,
		},
		{
			title: "Membership Packages",
			path: "/membership-packages/default",
			icon: "award",
			type: "link",
			active: false,
		},
		{
			title: "App Constants",
			path: "/app-constants/default",
			icon: "tag",
			type: "link",
			active: false,
		},
		{
			title: "Tracking Code",
			path: "/tracking-code/default",
			icon: "gift",
			type: "link",
			active: false,
		},
		{
			title: "Tax Rules",
			path: "/tax-rules/default",
			icon: "percent",
			type: "link",
			active: false,
		},
		{
			title: "Inventories",
			path: "/inventories/default",
			icon: "database",
			type: "link",
			active: false,
		},
		{
			title: "Water Suppliers",
			path: "/water-suppliers/default",
			icon: "cloud-rain",
			type: "link",
			active: false,
		},
		{
			title: "Admin Staff",
			path: "/admin-staff/default",
			icon: "key",
			type: "link",
			active: false,
		},
		{
			title: "vehicles",
			path: "/vehicles/default",
			icon: "truck",
			type: "link",
			active: false,
		},
		{
			title: "Delivery Staff",
			path: "/delivery-staff/default",
			icon: "send",
			type: "link",
			active: false,
		},
		{
			title: "Water cans",
			path: "/water-cans/default",
			icon: "archive",
			type: "link",
			active: false,
		},
		{
			title: "Payments",
			path: "/payments/default",
			icon: "credit-card",
			type: "link",
			active: false,
		},
		{
			title: "Orders",
			path: "/orders/default",
			icon: "shopping-cart",
			type: "link",
			active: false,
		},
		{
			title: "Whatsapp Templates",
			path: "/whatsapp-message-templates/default",
			icon: "file-text",
			type: "link",
			active: false,
		},
		{
			title: "Login",
			path: "/auth/login",
			icon: "log-in",
			type: "link",
			active: false,
		},
		{
			title: "Past Orders On Map",
			path: "/past-orders-on-map/default",
			icon: "shopping-cart",
			type: "link",
			active: false,
		},
		{
			title: "Active Orders On Map",
			path: "/active-orders-on-map/default",
			icon: "shopping-cart",
			type: "link",
			active: false,
		},
	  {
		  title: "Interested Users",
		  path: "/interested-users/default",
		  icon: "users",
		  type: "link",
		  active: false,
	  },
	];
	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
