<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5>Edit Customer Address</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <app-search-bar
                (searchDataRecieved)="searchDataRecieved($event)"
              ></app-search-bar>
            </div>
            <div class="col-12 mt-5">
              <div *ngIf="apiLoaded | async">
                <google-map
                  width="100%"
                  height="650px"
                  [center]="center"
                  [options]="options"
                >
                  <ng-container >
                    <map-marker
                      #marker="mapMarker"
                      [position]="center"
                      [options]="markerOptionsAddress"
                      (mapDragend)="markerDragEnd($event)"
                      (mapClick)="openInfoWindow(marker, infoWindow)"
                    ></map-marker>
                    <map-info-window #infoWindow="mapInfoWindow">
                      <p>{{searchAddress}}</p>
                    </map-info-window>
                  </ng-container>
                </google-map>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 pt-4"> 
      <form  [formGroup]="editAddressForm" (ngSubmit)="onNext()"  novalidate>
          <div class="row">
              <div class="col-12">
                  <div class="form-group">
                      <label for="areaInput">Primary Address</label>
                      <input type="text" readonly formControlName="primary_address" class="form-control" id="areaInput" aria-describedby="nameHelp">
                    </div>
                    <div class="form-group">
                      <label for="areaInput">Secondary Address</label>
                      <input type="text" formControlName="secondary_address" class="form-control" id="areaInput" aria-describedby="nameHelp" placeholder="House/flat number/Appartment name">
                      <small *ngIf="!editAddressForm.get('secondary_address').valid && editAddressForm.get('secondary_address').touched" class="form-text text-muted red-text">
                        <div *ngIf="editAddressForm.get('secondary_address').errors['required']">Name is Required</div>
                    </small>
                    </div>
                    <div class="form-group">
                      <label for="areaInput">Landmark</label>
                      <input type="text" formControlName="landmark" class="form-control" id="areaInput" aria-describedby="nameHelp" placeholder="Landmark if any:">
                    </div>
                    <div class="form-group">
                      <label for="areaInput">Floor</label>
                      <input type="number" formControlName="floor" class="form-control" id="areaInput" aria-describedby="nameHelp" placeholder="Floor Number:">
                    </div>
              </div>
              <div class="col-12">
                  <div ngbDropdown class="d-inline-block">
                      <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>Lift Present: <span *ngIf="editAddressForm.controls.lift.value">Yes</span><span *ngIf="!editAddressForm.controls.lift.value">No</span></button>
                      
                      <div ngbDropdownMenu aria-labelledby="dropdownBasic1" >
                        <button (click)="selectLiftStatus(status)" *ngFor="let status of liftStatus" ngbDropdownItem>{{status}}</button>
                      </div>
                    </div>
              </div>
              <div class="col-12 pt-3">
                  <button type="submit" class="btn btn-primary btn-lg btn-block">Edit</button>

              </div>
          </div>
          


      </form>

  </div>
  </div>
</div>
